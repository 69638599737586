@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
* {
	margin: 0;
	padding: 0;
	outline: none;
}

body {
	font-family: 'Rasputin';
	font-family: "Montserrat", sans-serif;
	font-family: "Open Sans", sans-serif;
	font-weight: normal;
	font-style: normal;
	/* padding-top: 70px; */
}

/* large Desktops and laptops ----------- */
@media only screen and (min-width: 1225px) and (max-width: 2500px) {
	.container {
		max-width: 1170px
	}
}

/* Desktops and laptops  */
@media only screen and (min-width: 992px) and (max-width: 1224px) {
	.container {
		max-width: 960px;
	}
}

/* TABLETS (portrait and landscape) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.container {
		max-width: 720px
	}
}

/* TABLETS - SMARTPHONES LANDSCAPE (portrait and landscape)  */
@media only screen and (min-width: 481px) and (max-width: 767px) {
	.container {
		max-width: 640px;
	}
}

a {
	text-decoration: none;
	color: #2196f3;
}

a:hover {
	text-decoration: none;
}

ul {
	list-style-type: none;
	margin-bottom: 0px;
}

.button {
	padding: 9px 30px;
	background: #64506b;
	color: #fff;
	display: inline-block;
	font-size: 16px;
	font-weight: 500;
	border: 2px solid #64506b;
	border-radius: 25px;
	transition: 0.5s all ease;
	cursor: pointer;
	font-family: 'Rasputin';
}

.button:hover {
	/* background: #fff;
	color: #00a859; */
	box-shadow: 0 0 16px #3b3b3b;
}

img {
	width: 100%;
}

input,
select,
textarea {
	outline: none;
}

.container {
	width: 90%;
	padding: 0px 15px;
	margin-right: auto;
	margin-left: auto;
}

.container-fluid {
	width: 95%;
	padding: 0px 15px;
	margin-right: auto;
	margin-left: auto;
}

.relative {
	position: relative;
}

.absolute {
	position: absolute;
}

.content-center {
	display: grid;
	place-content: center;
}

.d-flex {
	display: flex;
}

.flex-between {
	display: flex;
	justify-content: space-between;
}

.flex-around {
	display: flex;
	justify-content: space-around;
}

.flex-between-center {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.flex-around-center {
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.flex-wrap {
	display: flex;
	flex-wrap: wrap;
}

.flex-column {
	display: flex;
	flex-direction: column;
}

@media max-width(480px) {
	.flex-column {
		flex-direction: column;
	}
}

.d-block {
	display: block;
}

.align-center {
	align-items: center;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.text-white {
	color: #fff;
}

.text-dark {
	color: #444;
}

.text-success {
	color: #4caf50;
}

.text-danger {
	color: #e91e63;
}

.text-primary {
	color: #2196f3;
}

.text-secondery {
	color: #009688;
}

.text-orange {
	color: #ff9800;
}

/*****Margin******/

.mt-1 {
	margin-top: 5px;
}

.mt-2 {
	margin-top: 10px;
}

.mt-3 {
	margin-top: 15px;
}

.mt-4 {
	margin-top: 20px;
}

.mt-5 {
	margin-top: 25px;
}

.mt-6 {
	margin-top: 40px;
}

.mb-1 {
	margin-bottom: 5px;
}

.mb-2 {
	margin-bottom: 10px;
}

.mb-3 {
	margin-bottom: 15px;
}

.mb-4 {
	margin-bottom: 20px;
}

.mb-5 {
	margin-bottom: 25px;
}

.mb-6 {
	margin-bottom: 40px;
}

.ml-1 {
	margin-left: 5px;
}

.ml-2 {
	margin-left: 10px;
}

.ml-3 {
	margin-left: 15px;
}

.ml-4 {
	margin-left: 20px;
}

.ml-5 {
	margin-left: 25px;
}

.ml-6 {
	margin-left: 40px;
}

.mr-1 {
	margin-right: 5px;
}

.mr-2 {
	margin-right: 10px;
}

.mr-3 {
	margin-right: 15px;
}

.mr-4 {
	margin-right: 20px;
}

.mr-5 {
	margin-right: 25px;
}

.mr-6 {
	margin-right: 40px;
}



/***** Padding ******/

.pt-1 {
	padding-top: 5px;
}

.pt-2 {
	padding-top: 10px;
}

.pt-3 {
	padding-top: 15px;
}

.pt-4 {
	padding-top: 20px;
}

.pt-5 {
	padding-top: 25px;
}

.pt-6 {
	padding-top: 40px;
}

.pb-1 {
	padding-bottom: 5px;
}

.pb-2 {
	padding-bottom: 10px;
}

.pb-3 {
	padding-bottom: 15px;
}

.pb-4 {
	padding-bottom: 20px;
}

.pb-5 {
	padding-bottom: 25px;
}

.pb-6 {
	padding-bottom: 40px;
}

.pl-1 {
	padding-left: 5px;
}

.pl-2 {
	padding-left: 10px;
}

.pl-3 {
	padding-left: 15px;
}

.pl-4 {
	padding-left: 20px;
}

.pl-5 {
	padding-left: 25px;
}

.pl-6 {
	padding-left: 40px;
}

.pr-1 {
	padding-right: 5px;
}

.pr-2 {
	padding-right: 10px;
}

.pr-3 {
	padding-right: 15px;
}

.pr-4 {
	padding-right: 20px;
}

.pr-5 {
	padding-right: 25px;
}

.pr-6 {
	padding-right: 40px;
}


/**** mx/ my****/
.mx-1 {
	margin: 0px 5px;
}

.mx-2 {
	margin: 0px 10px;
}

.mx-3 {
	margin: 0px 15px;
}

.mx-4 {
	margin: 0px 20px;
}

.mx-5 {
	margin: 0px 25px;
}

.mx-6 {
	margin: 0px 40px;
}

.my-1 {
	margin: 5px 0px;
}

.my-2 {
	margin: 10px 0px;
}

.my-3 {
	margin: 15px 0px;
}

.my-4 {
	margin: 20px 0px;
}

.my-5 {
	margin: 25px 0px;
}

.my-6 {
	margin: 40px 0px;
}


/*****px /py ******/

.px-1 {
	padding: 0px 5px;
}

.px-2 {
	padding: 0px 10px;
}

.px-3 {
	padding: 0px 15px;
}

.px-4 {
	padding: 0px 20px;
}

.px-5 {
	padding: 0px 25px;
}

.px-6 {
	padding: 0px 40px;
}

.py-1 {
	padding: 5px 0px;
}

.py-2 {
	padding: 10px 0px;
}

.py-3 {
	padding: 15px 0px;
}

.py-4 {
	padding: 20px 0px;
}

.py-5 {
	padding: 25px 0px;
}

.py-6 {
	padding: 40px 0px;
}
