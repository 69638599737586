body{
    padding-top: 80px;
}
.header{
    position: fixed;
    top: 0;
    z-index: 99;
    width: 100%;
    padding: 10px 0px;
    box-shadow: 0 0 10px #ddd;
    background: #fff;
}
.header .logo{
    width: 12%;
}
.header .menu{}

.header .menu ul li{
    font-family: 'Rasputin';
    padding: 20px;
    font-size: 16px;
    font-weight: 600;
}
.header .menu ul li a{
    color: #000;
}
